import React, { useState } from 'react';
import COS from 'cos-js-sdk-v5';
import { Modal, Button } from 'react-bootstrap';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin, ToolbarProps } from '@react-pdf-viewer/default-layout';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole, toggleDepartment} from '../actions/index';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import waterMark from '../images/logo-orig2.png';


const renderPage = (props) => (
    <>
        {props.canvasLayer.children}
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%'
            }}
        >
            <img src={waterMark} style={{width: `${40 * props.scale}rem`, opacity: 0.5}} />
        </div>
        {props.annotationLayer.children}
        {props.textLayer.children}
    </>
);

const ViewImage = (props) => {
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;


    const renderToolbar = () => (
        <Toolbar style={{backgroundColor: '#292929'}}>
            {(slots) => {
                const {
                    CurrentPageInput,
                    EnterFullScreen,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    ShowSearchPopover,
                    Zoom,
                    ZoomIn,
                    ZoomOut,
                } = slots;
                return (
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                        }}
                    >
                        <div className="rpv-toolbar__left">
                            <div style={{ padding: '0px 2px'}}>
                                <ShowSearchPopover />
                            </div>
                            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                <GoToPreviousPage />
                            </div>
                            <span className="rpv-page-navigation__current-page-input">
                                <CurrentPageInput style={{background: '#121212'}} type="text" data-testid="page-navigation__current-page-input" aria-label="Enter a page number" className="rpv-core__textbox" placeholder="" value="1" />
                            </span>
                            <span className="rpv-toolbar__label" style={{textIndent: '0.5em'}}> / <NumberOfPages /></span>
                            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                <GoToNextPage />
                            </div>
                        </div>
                        <div className="rpv-toolbar__center">
                            <div style={{ padding: '0px 2px' }}>
                                <ZoomOut />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <Zoom />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <ZoomIn />
                            </div>
                        </div>
                        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                            <EnterFullScreen />
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );

    const SECRET_ID = process.env.REACT_APP_SECRET_ID;
    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
    const REGION = process.env.REACT_APP_REGION;
    const { filename, filepath, filetype, downloadPermission, bucket } = props;

    const [pdfURL, setPdfURL] = useState(null);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const onHandleView = () => {
        let downloadName;
        if (filepath) {
            downloadName = `${filepath}/${filename}`;
        }
        else {
            downloadName = filename;
        }
        if (!downloadPermission) {
            window.alert('不允许下载文件');
        }
        else if (filetype === 'FILE') {
            let cos = new COS({
                SecretId: SECRET_ID,
                SecretKey: SECRET_KEY
            });

            cos.getObjectUrl({
                Bucket: bucket,
                Region: REGION,
                Key: downloadName,
                Sign: true
            },function (err,data) {
                if (err) return console.log(err);
                console.log(data)
                setPdfURL(data.Url);
                setShow(true);
            });
        }
        else {
            window.alert('不允许本操作');
        }
    }

    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <>
            <svg onClick={onHandleView} style={{margin: 5, cursor: 'pointer'}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="eye" role="img" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 576 512" className="svg-inline--fa fa-eye fa-w-18 fa-3x">
                <path fill="currentColor" d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" className="">
                </path>
            </svg>
            {
                pdfURL &&
                <Modal show={show} onHide={handleClose} size="xl">
                    <Modal.Body style={{backgroundColor: '#EEEEEE99'}}>
                        <h6 className="text-center">{filename}</h6>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                                <div
                                    className="viewerContainer"
                                    style={{
                                        height: "100vh",
                                        width: "100%",
                                        margin: "0 auto"
                                    }}
                                >
                                    {/* <div className="rpv-default-layout__toolbar">{renderToolbar()}</div> */}
                                    <Viewer 
                                        fileUrl={pdfURL} 
                                        plugins={[
                                            defaultLayoutPluginInstance,
                                            // toolbarPluginInstance
                                        ]}
                                        defaultScale={SpecialZoomLevel.PageFit}
                                        theme='dark'
                                        renderPage={renderPage}
                                    />
                                </div>    
                            </Worker>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        关闭
                    </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    )
}

const styles = {
    shadowBtn: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(0, 0, 0)',
        width: 35,
        height: 35,
        padding: 0,
        textAlign: 'center',
        fontSize: 18,
        border: 0,
        outline: 'none',
        cursor: 'pointer',
        textDecoration: 'none',
        borderRadius: 35,
        opacity: 1,
        pointerEvents: 'all',
        boxShadow: 'rgb(0 0 0 / 20%) 2px 2px 3px'
    }
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role,
        department: state.department
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole, toggleDepartment: toggleDepartment}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(ViewImage);