export const addTokenHeader = () => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    };
  
    if (token) {
        config.headers['x-auth-token'] = token;
    }
  
    return config;
};

export const logout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('token');
}

export const getDepartments = () => {
    return {
        "UPPER_MANAGEMENT": "董事会（含高管）",
        "SALES": "营销部",
        "DESIGN_INSTITUTE": '设计院（含总师办）',
        "PROJECT_MANAGEMENT": "项目管理部",
        "QUALITY_CONTROL": "质量与控制部",
        "OFFICE_MANAGEMENT": "总经办",
        "PURCHASING": "采购部",
        "FINANCE": "财务部",
        "INFRASTRUCTURE": "基建办",
        "OPERATION_MAINTENANCE_MANAGEMENT": "运维业务管理部"
    }
}

export const getEnglishDepartmentNames = () => {
    const departmentNames = {
        '董事会（含高管）': 'UPPER_MANAGEMENT',
        '营销部': 'SALES',
        '设计院（含总师办）': 'DESIGN_INSTITUTE',
        '项目管理部': 'PROJECT_MANAGEMENT',
        '质量与控制部': 'QUALITY_CONTROL',
        '总经办': 'OFFICE_MANAGEMENT',
        '采购部': 'PURCHASING',
        '财务部': 'FINANCE',
        '基建办': 'INFRASTRUCTURE',
        '运维业务管理部': 'OPERATION_MAINTENANCE_MANAGEMENT'
    }
    return departmentNames;
}

export const getEnglishToURL = (inName) => {
    const urls = {
        "UPPER_MANAGEMENT": "upper-management",
        "SALES": "sales",
        "DESIGN_INSTITUTE": "design-institute", 
        "PROJECT_MANAGEMENT": "project-management",
        "QUALITY_CONTROL": "quality-control",
        "OFFICE_MANAGEMENT": "office-management",
        "PURCHASING": "purchasing",
        "FINANCE": "finance",
        "INFRASTRUCTURE": "infrastructure",
        "OPERATION_MAINTENANCE_MANAGEMENT": "operation-maintenance-management"
    }
    return urls[inName];
}

export const getChineseToURL = (inName) => {
    const urls = {
        "董事会（含高管）": "upper-management",
        "营销部": "sales",
        "设计院（含总师办）": "design-institute",
        "项目管理部": "project-management",
        "质量与控制部": "quality-control",
        "总经办": "office-management",
        "采购部": "purchasing",
        "财务部": "finance",
        "基建办": "infrastructure",
        "运维业务管理部": "operation-maintenance-management"
    }
    return urls[inName];
}

export const getURLToChinese = (inURL) => {
    const urls = {
        "upper-management": "董事会（含高管）",
        "sales": "营销部",
        "design-institute": "设计院（含总师办）",
        "project-management": "项目管理部",
        "quality-control": "质量与控制部",
        "office-management": "总经办",
        "purchasing": "采购部",
        "finance": "财务部",
        "infrastructure": "基建办",
        "operation-maintenance-management": "运维业务管理部"
    }
    return urls[inURL];
}

export const getDates = (mongoDate) => {
    const currentTime = new Date(mongoDate);
    const year = currentTime.getFullYear();
    const month = currentTime.getMonth() + 1;
    const day = currentTime.getDate();
    return { year, month, day }
}

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return ' - ';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const getDepartmentBucket = (departmentName) => {
    if (departmentName === 'office-management') {
        return process.env.REACT_APP_BUCKET_OFFICE_MANAGEMENT;
    }
    else if (departmentName === 'operation-maintenance-management') {
        return process.env.REACT_APP_BUCKET_OPERATION_MAINTENANCE_MANAGEMENT;
    }
    else {
        return null
    }
}