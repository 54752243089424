import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from '../pages/Login';
import UserList from '../pages/UserList';
import NewUser from '../pages/NewUser';
import EditUser from '../pages/EditUser';
import UpdatePassword from '../pages/UpdatePassword';
import NotFound from '../pages/NotFound';
import DisplayItemsTable from '../pages/DisplayItemsTable';
import HistoryTable from '../pages/HistoryTable';
import DisplayDepartmentItemsTable from '../pages/DisplayDepartmentItemsTable';

export default () => (
	<div>
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={DisplayItemsTable}/>
                <Route path="/login" exact component={Login}/>
                <Route path="/users" exact component={UserList}/>
                <Route path="/newuser" exact component={NewUser}/>
                <Route path="/history" exact component={HistoryTable}/>
                <Route path="/passwordupdate" exact component={UpdatePassword}/>
                <Route path="/user/:id/edit" exact component={EditUser} />
                <Route path="/department/:department" component={DisplayDepartmentItemsTable}/>
                <Route path="/:id" component={DisplayItemsTable}/>
                <Route path="*" component={NotFound}/>
            </Switch>
        </BrowserRouter>
  	</div>
)