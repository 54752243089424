import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { useTable, usePagination, useAsyncDebounce, useGlobalFilter } from 'react-table';
import { getDates } from '../utils';

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)
  
    return (
        <span>
            搜索:{' '}
            <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} 个实体...`}
                style={{
                    fontSize: '1.1rem',
                    border: '0',
                }}
            />
        </span>
    )
}

const HistoricalEntitiesTable = (props) => {
    const { entities } = props;
    const dataHeads = [
        {Header: '文件名', accessor: 'entityname'},
        {Header: '存储位置', accessor: 'entitylocation'},
        {Header: '文件类', accessor: 'entitytype'},
        {Header: '操作时间', accessor: 'update_date'},
        {Header: '操作', accessor: 'entityaction'},
        {Header: '操作用户名', accessor: 'username'}
    ]
    
    for (var i=0; i<entities.length; i++) {
        const {year, month, day} = getDates(entities[i].update_date);
        entities[i].update_date = `${year}年${month}月${day}日`;
    }

    const data = React.useMemo(
        () => entities,
        []
    )

    const columns = React.useMemo(
        () => dataHeads,
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = useTable(
        { 
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination
    );
    return (
        <>
            <Table {...getTableProps()} style={{marginTop: 20}} >
                <thead>
                    <tr>
                        <th
                            colSpan={visibleColumns.length}
                            style={{
                                textAlign: 'left',
                            }}
                        >
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                        </th>
                    </tr>
                {
                    headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                        {
                        headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                                {
                                    column.render('Header')
                                }
                            </th>
                        ))}
                        </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {
                page.map(row => {
                    prepareRow(row)
                    return (
                    <tr {...row.getRowProps()}>
                        {
                            row.cells.map(cell => {
                                return (
                                    <td style={{width: cell.column.Header === '实体名' && '50%'}} {...cell.getCellProps()}>
                                        {
                                            cell.render('Cell')
                                        }
                                    </td>
                                )
                        })}
                    </tr>
                    )
                })}
                </tbody>
            </Table>
            <div className="pagination">
                <Button style={{margin: 1}} variant='primary' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </Button>{' '}
                <Button style={{margin: 1}} variant='primary' onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </Button>{' '}
                <Button style={{margin: 1}} variant='primary' onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </Button>{' '}
                <Button style={{margin: 1}} variant='primary' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </Button>{' '}
                <span style={{display: 'block', marginTop: 'auto', marginBottom: 'auto', marginLeft: 5}}> 第{' '}
                    <strong>
                        {pageIndex + 1}/{pageOptions.length}
                    </strong>{' '} 页 | 访问页面：{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    style={{
                        height: 30,
                        marginTop: 5,
                        marginBottom: 5
                    }}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        显示 {pageSize} / 页
                    </option>
                ))}
                </select>
            </div>
        </>
    )
}

export default HistoricalEntitiesTable;