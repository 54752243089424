import axios from 'axios';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import SiteNav from '../components/SiteNav';
import HistoricalEntitiesTable from '../components/HistoricalEntitiesTable';
import { addTokenHeader, logout, getDepartments } from '../utils';

const HistoryTable = (props) => {
    const [entities, setEntities] = useState();
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    const roleEnum = {
        'SUPER': '超级用户',
        'MANAGER': '管理员',
        'USER': '普通用户'
    }

    const entityActionEnum = {
        'UPLOAD': '上传',
        'DELETE': '删除',
        'EDIT': '编辑'
    }

    const entityTypeEnum = {
        'FILE': '文件',
        'FOLDER': '文件夹',
    }

    const departmentEnum = getDepartments();

    useEffect(() => {
        getEntities();
    }, [])

    const getEntities = () => {
        axios.get('/api/files/manager/entities', addTokenHeader())
            .then(res => {
                const items = []
                for (var i=0; i<res.data.length; i++) {
                    items.push(res.data[i]);
                    items[i].department = departmentEnum[items[i].department]
                    items[i].entityaction = entityActionEnum[items[i].entityaction]
                    items[i].entitytype = entityTypeEnum[items[i].entitytype]
                    items[i].role = roleEnum[items[i].role]
                }
                items.sort((a, b) => (a.update_date < b.update_date) ? 1 : -1)
                setEntities(items);
                setLoading(false);
                props.toggleLogin(true);
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 400) {
                    logout();
                    props.toggleLogin(false);
                    props.toggleRole(null);
                    history.push("/login");
                }
                else {
                    window.alert(error.response.data.msg);
                    history.goBack();
                }
            })
    }

    return (
        <div>
            <SiteNav />
            <div className="container" style={{paddingTop: 50, paddingBottom: 50}}>
                {
                    !loading && entities ?
                    <HistoricalEntitiesTable entities={entities} /> :
                    <Spinner style={{marginLeft: "50%", marginRight: "50%"}} animation="grow"/>
                }
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(HistoryTable);