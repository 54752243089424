import React from 'react';
import COS from 'cos-js-sdk-v5';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole, toggleDepartment} from '../actions/index';


const DownloadImage = (props) => {
    const SECRET_ID = process.env.REACT_APP_SECRET_ID;
    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
    const REGION = process.env.REACT_APP_REGION;
    const { filename, filepath, filetype, downloadPermission, bucket } = props;

    const onHandleDownload = () => {
        let downloadName;
        if (filepath) {
            downloadName = `${filepath}/${filename}`;
        }
        else {
            downloadName = filename;
        }
        if (!downloadPermission) {
            window.alert('不允许下载文件');
        }
        else if (filetype === 'FILE') {
            let cos = new COS({
                SecretId: SECRET_ID,
                SecretKey: SECRET_KEY
            });

            cos.getObjectUrl({
                Bucket: bucket,
                Region: REGION,
                Key: downloadName,
                Sign: true
            },function (err,data) {
                if (err) return console.log(err);
                var downloadUrl = data.Url + (data.Url.indexOf('?') > -1 ? '&' : '?') + 'response-content-disposition=attachment';
                window.open(downloadUrl);
            });
        }
        else {
            window.alert('不允许下载文件夹');
        }
    }

    return (
        <svg onClick={onHandleDownload} style={{margin: 5, cursor: 'pointer'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-arrow-down" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z"/>
            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
        </svg>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role,
        department: state.department
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole, toggleDepartment: toggleDepartment}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(DownloadImage);