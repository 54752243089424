import axios from 'axios';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, ProgressBar, Spinner } from 'react-bootstrap';
import COS from 'cos-js-sdk-v5';
import SiteNav from '../components/SiteNav';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import { getDepartmentBucket, getURLToChinese, getEnglishDepartmentNames } from '../utils/index';
import AddFileImage from '../components/AddFileImage';
import FileTable from '../components/FileTable';
import { addTokenHeader, logout, formatBytes } from '../utils';

const DisplayDepartmentItemsTable = (props) => {
    const SECRET_ID = process.env.REACT_APP_SECRET_ID;
    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
    const REGION = process.env.REACT_APP_REGION;

    const departmentFromURL = props.match.params.department;
    const prepended = props.match.url;
    const id = props.location.pathname.replace(prepended,'').substring(1);
    const { role } = props;
    let history = useHistory();
    const [data, setData] = useState(null);
    const [bucket, setBucket] = useState(null);
    const [departmentChinese, setDepartmentChinese] = useState(null);
    const [uploadingStatus, setUploadingStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [files, setFiles] = useState(false);
    const [fileIndex, setFileIndex] = useState(0);
    const [folder, setFolder] = useState(false);
    const [folderName, setFolderName] = useState(null);
    const [currentPath, setCurrentPath] = useState();
    const [show, setShow] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setLoading(true);
        const currentBucket = getDepartmentBucket(departmentFromURL);
        setBucket(currentBucket);
        const userDepartmentChinese = getURLToChinese(departmentFromURL);
        setDepartmentChinese(userDepartmentChinese);
        const englishDepartments = getEnglishDepartmentNames();
        const englishDepartmentName = englishDepartments[userDepartmentChinese];
        const payload = {
            id,
            bucket: currentBucket,
            englishDepartmentName
        }
        setCurrentPath(id.split("/"))
        axios.post(`/api/${departmentFromURL}/files`, payload, addTokenHeader())
            .then(res => {
                let thisLevelItems = [];
                for (var i=0; i<res.data.length; i++) {
                    const thisLevelItemName = res.data[i]['Key'].split("/")[0];
                    let fileType;
                    if (res.data[i]['Key'].split("/").length > 1) {
                        fileType = 'FOLDER';
                    }
                    else {
                        fileType = 'FILE';
                    }
                    thisLevelItems.push(
                        {
                            'name': thisLevelItemName, 
                            'type': fileType, 
                            'size': res.data[i].Size, 
                            'modified': res.data[i].LastModified,
                            'downloadPermission': res.data[i].downloadPermission,
                            'editPermission': res.data[i].editPermission,
                            'pdfViewPermission': res.data[i].pdfViewPermission,
                            'deletePermission': res.data[i].deletePermission
                        }
                    );
                }
                const result = []
                const map = new Map();
                for (const item of thisLevelItems) {
                    if (!map.has(item.name)){
                        map.set(item.name, true);
                        result.push({
                            name: item.name,
                            type: item.type,
                            size: item.size,
                            modified: item.modified,
                            downloadPermission: item.downloadPermission,
                            editPermission: item.editPermission,
                            pdfViewPermission: item.pdfViewPermission,
                            deletePermission: item.deletePermission
                        })
                    }
                }
                result.sort((a, b) => (a.name < b.name) ? 1 : -1)
                result.sort((a, b) => (a.type < b.type) ? 1 : -1)
                setData(result);
                setLoading(false);
                props.toggleLogin(true);
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 400) {
                    logout();
                    props.toggleLogin(false);
                    props.toggleRole(null);
                    history.push("/login");
                }
                else if (error.response.status === 403) {
                    setLoading(false);
                    window.alert(error.response.data.msg);
                    history.push("/");
                }
                else {
                    setLoading(false);
                    window.alert(error.response.data.msg);
                    history.push("/");
                }
            })
    }, [])

    async function uploadFile() {
        setUploading(true);
        await sendDataPromise(0);
        window.location.reload(false);
    }

    function sendDataPromise(index) {
        return new Promise((resolve) => {
            setFileIndex(index);
            let uploadFilepath;
            if (id) {
                uploadFilepath = `${id}/${files[index].name}`
            }
            else {
                uploadFilepath = files[index].name
            }
            let isAlreadyInData = false;
            for (var i=0; i<data.length; i++) {
                if (files[index].name.trim() === data[i].name.trim()) {
                    isAlreadyInData = true;
                }
            }
            if (isAlreadyInData) {
                window.alert(`已有文件利用这个名字: ${files[index].name}`);
                if (index + 1 < files.length) {
                    return resolve(sendDataPromise(index + 1));
                }
                else {
                    return resolve();
                }
            }
            else if (files) {
                var cos = new COS({
                    SecretId: SECRET_ID,
                    SecretKey: SECRET_KEY
                });
                cos.sliceUploadFile({
                    Bucket: bucket,
                    Region: REGION,
                    Key: uploadFilepath,
                    Body: files[index],
                    TaskReady: function(taskId) { /*Optional*/
                        console.log(taskId);
                    },
                    onHashProgress: function (progressData) { /* Optional */
                        console.log(JSON.stringify(progressData));
                    },
                    onProgress: function (progressData) { /* Optional */
                        console.log(JSON.stringify(progressData));
                        setUploadingStatus(progressData.percent * 100);
                    }
                }, function(err, data) {
                    console.log(err || data);
                    if (!err) {
                        const payload = {
                            entityname: uploadFilepath,
                            entitytype: 'FILE',
                            entityaction: 'UPLOAD',
                            entitylocation: departmentChinese
                        }
                        axios.post('/api/files/manager/update', payload, addTokenHeader())
                        .then(res => {
                            if (index + 1 < files.length) {
                                return resolve(sendDataPromise(index + 1));
                            }
                            else {
                                return resolve(res);
                            }
                        }).catch(err => {
                            window.alert(err);
                            if (index + 1 < files.length) {
                                return resolve(sendDataPromise(index + 1));
                            }
                            else {
                                return resolve(err);
                            }
                        });
                    }
                    else {
                        window.location.reload(false);
                    }
                });
            }
        })
    }

    const uploadFolder = () => {
        let uploadFolderPath;
        if (id) {
            uploadFolderPath = `${id}/${folderName}/`
        }
        else {
            uploadFolderPath = `${folderName}/`
        }
        let isAlreadyInData = false;
        for (var i=0; i<data.length; i++) {
            if (folderName.trim() === data[i].name.trim()) {
                isAlreadyInData = true;
            }
        }
        if (isAlreadyInData) {
            window.alert("已有文件夹利用这个名字");
        }
        else if (folderName) {
            var cos = new COS({
                SecretId: SECRET_ID,
                SecretKey: SECRET_KEY
            });
            cos.putObject({
                Bucket: bucket,
                Region: REGION,
                Key: uploadFolderPath,
                StorageClass: 'STANDARD',
                Body: '',
                onProgress: function(progressData) {
                    console.log(JSON.stringify(progressData));
                }
            }, function(err, data) {
                console.log(err || data);
                if (!err) {
                    const payload = {
                        entityname: uploadFolderPath,
                        entitytype: 'FOLDER',
                        entityaction: 'UPLOAD',
                        entitylocation: departmentChinese
                    }
                    axios.post('/api/files/manager/update', payload, addTokenHeader())
                    .then(res => {
                        window.location.reload(false);
                    }).catch(err => {
                        window.location.reload(false);
                    });
                }
                else {
                    window.location.reload(false);
                }
            });
        }
    }

    const handleUpload = (event) => {
        const uploadFiles = event.target.files;
        setFiles(uploadFiles);
        setFolder(false);
    }

    const setViewFolderInput = () => {
        setFiles(false);
        setFolder(true);
    }

    const handleOnFolderNameUpdate = (e) => {
        e.preventDefault();
        setFolderName(e.target.value);
    }

    return (
        <div>
            <SiteNav />
            <div className="container" style={{paddingTop: 50, paddingBottom: 50}}>
                {
                    !loading && data ?
                    <>
                        <AddFileImage handleShow={handleShow} />
                        <div className="text-center">
                            {
                                id ?
                                <span><a href={`/department/${props.match.params.department}/`}> {`${departmentChinese}`} </a> /</span> :
                                <span> {`${departmentChinese}`} </span>
                            }
                            {
                                currentPath.map((route, ind) => {
                                    return (
                                        <span key={ind}>
                                            {
                                                ind === currentPath.length-1 ?
                                                <span> {route} </span> :
                                                <span><a href={`/department/${props.match.params.department}/${currentPath.filter((item, itemIndex) => itemIndex <= ind).join('/')}`}> {route} </a> /</span>
                                            }
                                        </span>
                                    )
                                })
                            }
                        </div>
                    </>:
                    <Spinner style={{marginLeft: "50%", marginRight: "50%"}} animation="grow"/>
                }
                {
                    !loading && data &&
                    <FileTable 
                        data={data} 
                        id={id} 
                        role={role} 
                        bucket={bucket} 
                        deleteEndpoint={`/api/${props.match.params.department}/files/delete`} 
                        entitylocation={departmentChinese} 
                        rootpath={`/department/${props.match.params.department}/`}
                    />
                }
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>上传</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="input-container">
                            <div className="button-wrap">
                                <div className="button" 
                                    style={{
                                        margin: 5,
                                        pointerEvents: uploading ? true && 'none' : 'auto',
                                        opacity: uploading ? true && 0.65 : 1
                                    }} 
                                    onClick={setViewFolderInput}
                                >
                                    文件夹
                                </div>
                            </div>
                            <div className="button-wrap">
                                <label className="button" style={{
                                        margin: 5,
                                        pointerEvents: uploading ? true && 'none' : 'auto',
                                        opacity: uploading ? true && 0.65 : 1
                                    }} 
                                    htmlFor="upload" 
                                >
                                    文件
                                </label>
                                <input id="upload" type="file" multiple style={{width: 10}} onChange={handleUpload} />
                            </div>
                        </div>
                        {
                            files &&
                            <div className="text-center" style={{marginTop: 10}}>
                                <p>文件名：{files[fileIndex].name}</p>
                                <p>文件大小：{formatBytes(Number(files[fileIndex].size))}</p>
                                <Button variant="info" onClick={uploadFile} disabled={uploading ? true : false}>上传</Button>
                                {
                                    uploadingStatus &&
                                    <div style={{marginTop: 10}}>
                                        <p>上传进度</p>
                                        <ProgressBar animated now={uploadingStatus} />
                                    </div>
                                }
                            </div>
                        }
                        {
                            folder &&
                            <div className="text-center" >
                                <Form>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Control type="text" placeholder="文件夹名" onChange={handleOnFolderNameUpdate} />
                                    </Form.Group>
                                </Form>
                                <Button variant="info" onClick={uploadFolder} disabled={uploading ? true : false}>上传</Button>
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} disabled={uploading ? true : false}>
                        取消
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(DisplayDepartmentItemsTable);