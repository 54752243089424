import React from 'react';
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole, toggleDepartment} from '../actions/index';
import { logout, getEnglishToURL } from '../utils';
import logo from '../images/logo-orig2.png';

const SiteNav = (props) => {
    const history = useHistory()
    
    const handleOnLogout = () => {
        logout();
        props.toggleLogin(false);
        props.toggleRole(null);
        props.toggleDepartment(null);
        history.push("/login");
    }

    const { isLoggedIn, role, department } = props;
    const departmentURL = getEnglishToURL(department);
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand>
                <a href="/">
                    <Image src={logo} alt="logo"/>
                </a>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto" />
                <Nav>
                    {
                        isLoggedIn && role === 'USER'&&
                        <NavDropdown title="文件系统" id="files-nav-dropdown">
                            <NavDropdown.Item href="/">项目文档</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href={`/department/${departmentURL}`}>我的部门</NavDropdown.Item>
                        </NavDropdown>
                    }
                    {
                        isLoggedIn && role === 'MANAGER'&&
                        <NavDropdown title="文件系统" id="files-nav-dropdown">
                            <NavDropdown.Item href="/">项目文档</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href={`/department/${departmentURL}`}>我的部门</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/history">实体历史操作列表</NavDropdown.Item>
                        </NavDropdown>
                    }
                    {
                        isLoggedIn && (role === 'SUPER') &&
                        <NavDropdown title="文件系统" id="files-nav-dropdown">
                            <NavDropdown.Item href="/">项目文档</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/department/office-management">总经办文档</NavDropdown.Item>
                            <NavDropdown.Item href="/department/operation-maintenance-management">运维业务管理文档</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/history">实体历史操作列表</NavDropdown.Item>
                        </NavDropdown>
                    }
                    {
                        isLoggedIn &&
                        <NavDropdown title="个人中心" id="user-nav-dropdown">
                            <NavDropdown.Item href="/passwordupdate">设置密码</NavDropdown.Item>
                        </NavDropdown>
                    }
                    {
                        isLoggedIn && (role === 'SUPER') &&
                        <NavDropdown title="用户管理" id="user-nav-dropdown">
                            <NavDropdown.Item href="/users">用户列表</NavDropdown.Item>
                            <NavDropdown.Item href="/newuser">创造新用户</NavDropdown.Item>
                        </NavDropdown>
                    }
                    {
                        isLoggedIn && (role === 'MANAGER') &&
                        <NavDropdown title="用户管理" id="user-nav-dropdown">
                            <NavDropdown.Item href="/users">用户列表</NavDropdown.Item>
                        </NavDropdown>
                    }
                    <Nav.Link 
                        href={isLoggedIn ? "" : "/login"}
                        onClick={isLoggedIn && handleOnLogout}
                    >
                        {isLoggedIn ? '退出': '登录'}
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role,
        department: state.department
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole, toggleDepartment: toggleDepartment}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(SiteNav);