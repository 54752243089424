import axios from 'axios';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { addTokenHeader, logout } from '../utils';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole, toggleDepartment} from '../actions/index';

const DeleteImage = (props) => {
    const history = useHistory();
    const { filename, filepath, filetype, deletePermission, deleteEndpoint, bucket, entitylocation } = props;

    const handleOnDelete = () => {
        let deleteItem;
        if (filepath) {
            deleteItem = `${filepath}/${filename}`;
        }
        else {
            deleteItem = filename;
        }
        const payload = {
            deleteItem,
            bucket
        }
        if (!deletePermission) {
            window.alert('不允许删除本文件');
        }
        else {
            const conf = window.confirm('你是否确定要删除本文件')
            if (conf) {
                if (filetype === 'FILE') {
                    console.log(deleteEndpoint);
                    axios.post(deleteEndpoint, payload, addTokenHeader())
                    .then(res => {
                        window.location.reload(false);
                    })
                    .then(res => {
                        const payload = {
                            entityname: deleteItem,
                            entitytype: 'FILE',
                            entityaction: 'DELETE',
                            entitylocation: entitylocation
                        }
                        axios.post('/api/files/manager/update', payload, addTokenHeader())
                    })
                    .then(res => {
                        window.location.reload(false);
                    })
                    .catch(error => {
                        console.log(error);
                        if (error.response.status === 401 || error.response.status === 400) {
                            logout();
                            props.toggleLogin(false);
                            props.toggleRole(null);
                            history.push("/login");
                        }
                        else {
                            window.alert(error.response.data.msg);
                        }
                    })
                }
                else {
                    window.alert('暂时不允许删除文件夹');
                }
            }   
        }
    }

    return (
        <svg onClick={handleOnDelete} style={{margin: 5, cursor: 'pointer'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
        </svg>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role,
        department: state.department
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole, toggleDepartment: toggleDepartment}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(DeleteImage);