import {combineReducers} from 'redux';
import LoginReducer from './loginReducer';
import RoleReducer from './roleReducer';
import DepartmentReducer from './departmentReducer';

const allReducers = combineReducers({
    isLoggedIn: LoginReducer,
    role: RoleReducer,
    department: DepartmentReducer
});

export default allReducers