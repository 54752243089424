import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button, Alert, Card } from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import SiteNav from '../components/SiteNav';
import { logout, addTokenHeader } from '../utils';

const UpdatePassword = (props) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();

    const handleOnCurrentPasswordUpdate = (e) => {
        e.preventDefault();
        setCurrentPassword(e.target.value);
    }

    const handleOnNewPasswordUpdate = (e) => {
        e.preventDefault();
        setNewPassword(e.target.value);
    }

    const handleOnConfirmNewPasswordUpdate = (e) => {
        e.preventDefault();
        setConfirmNewPassword(e.target.value);
    }

    const handleOnSubmit = (e) => {
        const payload = {
            currentPassword,
            newPassword,
            confirmNewPassword
        };

        axios.post('/api/user/password/edit', payload, addTokenHeader())
        .then(res => {
            setError('')
            history.push('/');
        }).catch(error => {
            if (error.response.status === 400 || error.response.status === 401) {
                logout();
                props.toggleLogin(false);
                props.toggleRole(null);
                history.push('/login');
            }
            else {
                setError(error.response.data.msg);
            }
        });
    };

    return (
        <div>
            <SiteNav />
            <div style={{padding: 40}}>
                <h1 className="text-center">
                    设置密码
                </h1>
                <div className="container" style={{marginTop: 30, maxWidth: 600}}>
                    <Card>
                        <Card.Body>
                            <Form>
                                {
                                    error &&
                                    <Alert variant="danger">
                                        {error}
                                    </Alert>
                                }
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>目前的密码</Form.Label>
                                    <Form.Control type="password" placeholder="输入现有的密码" onChange={handleOnCurrentPasswordUpdate} />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>新密码</Form.Label>
                                    <Form.Control type="password" placeholder="输入新密码" onChange={handleOnNewPasswordUpdate} />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>新密码确认</Form.Label>
                                    <Form.Control type="password" placeholder="重输入新密码" onChange={handleOnConfirmNewPasswordUpdate} />
                                </Form.Group>
                                <div style={{textAlign: "center"}}>
                                    <Button variant="primary" onClick={handleOnSubmit}>
                                        更新密码
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(UpdatePassword);