import React from 'react';
import { Table } from 'react-bootstrap';
import { useTable } from 'react-table';
import FolderImageSmall from './FolderImageSmall';
import FileImageSmall from './FileImageSmall';
import DeleteImage from './DeleteImage';
import DownloadImage from './DownloadImage';
import { getDates, formatBytes } from '../utils';
import ViewImage from './ViewImage';
import EditImage from './EditImage';


const IconImg = (props) => {
    const { type, cell, name, id, rootpath } = props;
    if (type === 'FOLDER') {
        return (
            <>
                <FolderImageSmall />
                <a href={id ? `${rootpath}${id}/${name}` : `${rootpath}${name}`}>
                    {cell}
                </a>
            </>
        )
    }
    else {
        return (
            <>
                <FileImageSmall />
                {cell}
            </>
        )
    }
}

const OperationDisplay = (props) => {
    const { 
        deletePermission, 
        editPermission, 
        pdfViewPermission, 
        downloadPermission, 
        filename, 
        filepath, 
        filetype, 
        bucket, 
        deleteEndpoint, 
        entitylocation 
    } = props;
    return (
        <>  
            {/* {
                editPermission &&
                <EditImage filename={filename} filepath={filepath} filetype={filetype} editPermission={editPermission} />
            } */}
            {
                pdfViewPermission &&
                <ViewImage 
                    filename={filename} 
                    filepath={filepath} 
                    filetype={filetype} 
                    downloadPermission={pdfViewPermission} 
                    bucket={bucket}
                />
            }
            {
                deletePermission &&
                <DeleteImage 
                    filename={filename} 
                    filepath={filepath} 
                    filetype={filetype} 
                    deletePermission={deletePermission} 
                    deleteEndpoint={deleteEndpoint} 
                    bucket={bucket} 
                    entitylocation={entitylocation}
                />
            }
            {
                downloadPermission &&
                <DownloadImage 
                    filename={filename} 
                    filepath={filepath} 
                    filetype={filetype} 
                    downloadPermission={downloadPermission} 
                    bucket={bucket}
                />
            }
            {/* {
                !pdfViewPermission && !deletePermission && !downloadPermission && !editPermission &&
                <span>-</span>
            } */}
            {
                !pdfViewPermission && !deletePermission && !downloadPermission &&
                <span>-</span>
            }
        </>
    )
}

const FileTable = (props) => {
    const { id, role, bucket, deleteEndpoint, entitylocation, rootpath } = props;
    const dataHeads = [
        {Header: '文件名', accessor: 'name'},
        {Header: '上传时间', accessor: 'modified'},
        {Header: '大小', accessor: 'size'},
        {Header: '操作', accessor: 'action'}
    ]
    const fileData = props.data;
    
    for (var i=0; i<fileData.length; i++) {
        const {year, month, day} = getDates(fileData[i].modified);
        fileData[i].modified = `${year}年${month}月${day}日`;
    }

    for (var i=0; i<fileData.length; i++) {
        const size = formatBytes(Number(fileData[i].size));
        fileData[i].size = size;
    }

    const data = React.useMemo(
        () => fileData,
        []
    )

    const columns = React.useMemo(
        () => dataHeads,
        []
    )

    const tableInstance = useTable({ columns, data });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = tableInstance

      return (
        <Table {...getTableProps()} style={{marginTop: 20}} >
            <thead>
            {
            headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                {
                headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>
                        {
                            column.render('Header')
                        }
                    </th>
                ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {
            rows.map(row => {
                prepareRow(row)
                return (
                <tr {...row.getRowProps()}>
                    {
                        row.cells.map(cell => {
                            return (
                                <td {...cell.getCellProps()}>
                                    {
                                        cell.column.Header === '文件名' ?
                                        <IconImg 
                                            type={cell.row.original.type} 
                                            name={cell.row.original.name}
                                            cell={cell.render('Cell')}
                                            id={id}
                                            rootpath={rootpath}
                                        /> :
                                        cell.render('Cell')
                                    }
                                    {
                                        cell.column.Header === "操作" &&
                                        <OperationDisplay 
                                            deletePermission={cell.row.original.deletePermission} 
                                            editPermission={cell.row.original.editPermission} 
                                            pdfViewPermission={cell.row.original.pdfViewPermission}
                                            downloadPermission={cell.row.original.downloadPermission}
                                            filename={cell.row.original.name} 
                                            filepath={id} 
                                            filetype={cell.row.original.type} 
                                            bucket={bucket}
                                            deleteEndpoint={deleteEndpoint}
                                            entitylocation={entitylocation}
                                        />
                                    }
                                </td>
                            )
                    })}
                </tr>
                )
            })}
            </tbody>
        </Table>
    )
}

export default FileTable;