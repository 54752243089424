import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import SiteNav from '../components/SiteNav';

const NotFound = () => {
    const history = useHistory();

    const handleOnGoHome = () => {
        history.push("/");
    }

    const handleOnGoBack = () => {
        history.goBack()
    }

    return (
        <div>
            <SiteNav />
            <div style={{padding: 40}}>
                <h1 className="text-center">
                    404 – 发生错误了
                </h1>
                <div style={{marginTop: 30, textAlign: "center"}}>
                    <Button variant="info" onClick={handleOnGoHome}>
                        回首页
                    </Button>{' '}
                    <Button variant="primary" onClick={handleOnGoBack}>
                        返回
                    </Button>{' '}
                </div>
            </div>
        </div>
    )
}

export default NotFound;