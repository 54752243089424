import React from 'react';

const AddFileImage = (props) => {
    const { handleShow } = props;
    return (
        <svg style={{marginBottom: 30}} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className="bi bi-plus-circle-fill center-image" viewBox="0 0 16 16">
            <path style={{cursor: 'pointer'}} onClick={handleShow} d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
        </svg>
    )
}

export default AddFileImage;