import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole, toggleDepartment} from '../actions/index';
import { addTokenHeader, logout } from '../utils';


const EditImage = (props) => {
    const { filename, filepath, filetype, editPermission } = props;

    const [updatedName, setUpdatedName] = useState(filename);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);

    const onHandleEdit = () => {
        setShow(true);
    }

    const onHandleSubmit = () => {
        let editName;
        let origName;
        if (filepath) {
            editName = `${filepath}/${updatedName}`;
            origName = `${filepath}/${filename}`;
        }
        else {
            editName = updatedName;
            origName = filename;
        }
        
        if (filetype === 'FOLDER') {
            editName = editName + '/';
            origName = origName + '/';
        }

        const payload = {
            editName,
            origName
        }

        if (!editPermission) {
            window.alert('不允许改文件名');
        }
        else {
            setLoading(true);
            axios.post('/api/files/edit', payload, addTokenHeader())
            .then(res => {
                console.log(res);
                window.location.reload(false);
                setLoading(false);
            }).catch(err => {
                console.log(err);
                setLoading(false);
            });
        }
    }

    const handleOnChange = (e) => {
        setUpdatedName(e.target.value);
    }

    return (
        <>
            <svg onClick={onHandleEdit} style={{margin: 5, cursor: 'pointer'}} aria-hidden="true" width="16" height="16" focusable="false" data-prefix="fas" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa fa-edit fa-w-18 fa-3x">
                <path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z" className="">
                </path>
            </svg>
            {
                <Modal show={show} onHide={handleClose}>
                    <Modal.Body>
                        {
                            loading ?
                            <Spinner style={{marginLeft: "50%", marginRight: "50%"}} animation="grow"/> :
                            <Form>
                                <Form.Group>
                                    <Form.Label>文件名</Form.Label>
                                    <Form.Control type="text" name="email" value={updatedName} onChange={handleOnChange} />
                                </Form.Group>
                            </Form>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" onClick={onHandleSubmit}>
                        更新
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        取消
                    </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role,
        department: state.department
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole, toggleDepartment: toggleDepartment}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(EditImage);